import Model from './model';
import Phrase from './phrase';

export default class PhraseLang extends Model {
    constructor(
        public rus: Phrase[] = [],
        public eng: Phrase[] = []) {
        super();
    }
}
