import { Component, h } from 'preact';

import style from './style.css';

type ButtonHandler = () => void;

export interface AlertButton {
    title?: string,
    handler?: ButtonHandler
}

interface Props {
    type: string;
    title?: string;
    message?: string;
    firstButton?: AlertButton;
    secondButton?: AlertButton;
    onClose: ButtonHandler;
    errorMessage?: string;
}

interface State {
    isShowError: boolean;
}

export default class Alert extends Component<Props, State> {
    private defaultBtnTitle: string = 'OK';
    private closeTimeOut: any = null;

    onButtonClick(handler?: ButtonHandler) {
        this.props.onClose();
        if (handler) {
            handler();
        }
    }

    componentDidMount() {
        this.setState({
            isShowError: false
        })
        this.onCloseTimeout();
    }

    private onCloseTimeout = (): void => {
        clearTimeout(this.closeTimeOut)

        if (!this.props.firstButton && !this.props.secondButton) {
            this.closeTimeOut = setTimeout(() => {
                this.onButtonClick();
            }, 3000);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.type !== this.props.type) {
            this.onCloseTimeout();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimeOut);
    }

    render(props: Props, state: State) {
        let buttons: AlertButton[] = [];

        if (props.firstButton) {
            buttons.push({
                title: props.firstButton?.title ?? this.defaultBtnTitle,
                handler: props.firstButton?.handler
            });
        }

        if (props.secondButton) {
            buttons.push({
                title: props.secondButton.title,
                handler: props.secondButton.handler
            });
        }

        return (
            <div class={style.alert}>
                <h3 class={style.title}>{props.title}</h3>
                {props.message &&
                    <span class={style.message}>{props.message}</span>}
                {props.errorMessage &&
                    <span 
                        class={`${style.error} ${!state.isShowError ? style.underline : ''}`} 
                        onClick={!state.isShowError ? () => this.setState({ isShowError: true }) : ()=>{}}
                    >{!state.isShowError ? 'Подробнее ▼' : props.errorMessage}</span>}
                <div class={style.buttons}>
                    {buttons.map(btn => (
                        <div class={style.button} onClick={this.onButtonClick.bind(this, btn.handler)}>
                            {btn.title}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}