import Model from './model';
import SubtitlesShort from './subtitles-short';

export default class SubtitlesList extends Model {
    constructor(
        public readonly skip: number = 0,
        public readonly limit: number = 0,
        public readonly total: number = 0,
        public readonly subtitles: SubtitlesShort[] = []) {
        super();
    }
}