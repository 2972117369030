import * as gravatar from "@litert/gravatar";

import jwt_decode, { JwtPayload } from "jwt-decode";

import MD5 from "crypto-js/md5";
import Model from './model';

interface AuthPayload extends JwtPayload {
    preferred_username: string;
    email_verified: boolean;
}

export default class User extends Model {
    public readonly id?: string;
    public readonly name?: string;

    constructor(
        public readonly authToken?: string,
        public readonly authenticated: boolean = false
    ) {
        super();

        gravatar.setMD5Function(function (name: string): string {
            return MD5(name).toString();
        });

        if (authToken) {
            const decoded = jwt_decode<AuthPayload>(authToken);
            this.id = decoded.sub as string;
            this.name = decoded.preferred_username;
        }
    }

    public getAvatar(size: number): string {
        return gravatar.getAvatarUrl({
            email: this.name ?? 'Anonymous',
            size: size,
            default: 'retro'
        });
    }
}
