import Model from './model';
import Phrase from './phrase';
import PhraseLang from './phrase-lang';

export default class Subtitles extends Model {
    constructor(
        public phrases: PhraseLang = new PhraseLang(),
        public published: boolean = false,
        public title: string = '',
        public prepared: boolean = false,
        public duration: number = 0,
        public readonly id: string = '',
        public readonly created: Date = new Date(),
        public readonly modified: Date = new Date(),
        public readonly created_by: string = '',
        public readonly modified_by: string = '') {
        super();
    }

    public videoUrl(librUrl: string): string {
        return `${librUrl}/watch/${this.id}?formats=hls&quality=FHD&with_subtitles=false`;
    }

    public waveformUrl(apiUrl: string): string {
        return `${apiUrl}/subtitles/${this.id}/waveform`;
    }

    public getPhrasesWithLang(lang: string): Phrase[] {
        const phrases: Phrase[] = this.phrases[lang as keyof PhraseLang];
        return phrases ?? [];
    }

    public setPhrasesWithLang(lang: string, phrases: Phrase[]): void {
        this.phrases[lang as keyof PhraseLang] = phrases;
    }
}
