export enum SubtitlesListSortOrder {
    None = 0,
    TitleAsc = 1 << 0,
    TitleDesc = 1 << 1,
    CreatedAsc = 1 << 2,
    CreatedDesc = 1 << 3,
    ModifiedAsc = 1 << 4,
    ModifiedDesc = 1 << 5,
    PreparedAsc = 1 << 6,
    PreparedDesc = 1 << 7,
    All = ~(~0 << 8)
}

export enum SubtitlesListSortBy {
    Title = 'title',
    Created = 'created',
    Modified = 'modified',
    Prepared = 'prepared'
}