import { Component, createRef, h } from 'preact';

import { Link } from 'preact-router/match';
import User from '../../models/user'
import style from './style.css';

interface Props {
    user: User;
    avatarSize: number;
}

export default class Header extends Component<Props> {
    private headerRef = createRef();

    public get height(): number {
        let headerBoundingBox = this.headerRef.current?.getBoundingClientRect();
        return headerBoundingBox.height ?? 0;
    }

    render(props: Props) {
        return (
            <header class={style.header} ref={this.headerRef}>
                <div class={style.header_wrapper}>
                    <div class={style.logotype}>
                        <div class={style.logotype_wrapper}>
                            <Link href='/'>START <span>SUBEDIT</span></Link>
                        </div>
                    </div>
                    <div class={style.auth}>
                        <div class={style.auth_wrapper}>
                            <Link href='#' activeClassName={style.active}>
                                <span>{props.user.name ?? 'Anonymous'}</span>
                                <img src={props.user.getAvatar(props.avatarSize)} alt={props.user.name} />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
};
