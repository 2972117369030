import Model from './model';

export default class WaveformData extends Model {
    constructor(
        public readonly version: number = 0,
        public readonly channels: number = 0,
        public readonly sample_rate: number = 0,
        public readonly samples_per_pixel: number = 0,
        public readonly bits: number = 0,
        public readonly length: number = 0,
        public readonly data: number[] = []) {
        super();
    }

    public get peaks(): number[] {
        return this.data.map(x => x / (Math.pow(2, this.bits) / 2));
    }
}
