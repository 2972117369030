import { AppContext, AppContextProps } from '../../context/app'
import { Component, createRef, h } from 'preact';
import { Route, Router } from 'preact-router';

import Alert from '../alert'
import { AlertButton } from '../alert'
import ApiClient from '../../api';
import Auth from '../../routes/auth';
import Config from '../../config';
import Edit from '../../routes/edit';
import ErrorCatcher from '../error';
import Header from '../header';
import List from '../../routes/list';
import NotFoundPage from '../../routes/notfound';
import Redirect from '../redirect';
import User from '../../models/user';
import style from './style.css';

interface Props {
}

interface State extends AppContextProps {
    headerHeight: number;
    error: Error;
    errorHidden: boolean;
    alertHidden: boolean;
    alertTitle?: string;
    alertMessage?: string;
    alertFirstButton?: AlertButton;
    alertSecondButton?: AlertButton;
    alertType: string;
    errorMessage?: string
}

export default class App extends Component<Props, State> {
    private client = new ApiClient(Config.apiHost, Config.defaultApiPath);
    private defaultUser = new User('', !Config.authEnabled);
    private headerRef = createRef();
    private errorRef = createRef();

    setUser(user: User) {
        this.setState({ user: user });
    }

    componentDidMount() {
        const header: Header = this.headerRef.current;
        this.setState({
            setUser: this.setUser.bind(this),
            showAlert: this.showAlert.bind(this),
            headerHeight: header?.height,
            errorHidden: true,
            alertHidden: true
        });
    }

    showAlert(
        type: string,
        title: string,
        message?: string,
        firstButton?: AlertButton,
        secondButton?: AlertButton,
        errorMessage?: any
    ) {
        this.setState({
            alertType: type,
            alertHidden: false,
            alertTitle: title,
            alertMessage: message,
            alertFirstButton: firstButton,
            alertSecondButton: secondButton,
            errorMessage: errorMessage
        });
    }

    closeAlert() {
        this.setState({
            alertHidden: true
        });
    }

    componentDidCatch(error: Error, info: any) {
        this.errorRef.current?.pushError(error);
    }

    render(props: Props, state: State) {
        let user: User = state.user || this.defaultUser;
        return (
            <AppContext.Provider value={state}>
                <Header ref={this.headerRef}
                    user={user}
                    avatarSize={Config.avatarSize}
                />
                <div class={style.page}>
                    <div class={style.wrapper}>
                        <Router>
                            <Route path='/auth/:params?'
                                component={Auth}
                                api={this.client}
                            />
                            <Route path='/list/:params?'
                                component={List}
                                api={this.client}
                                user={user}
                                headerHeight={state.headerHeight}
                                timezone={Config.dateTimezone}
                                limit={Config.listLimit}
                            />
                            <Route path='/list/:page/:params?'
                                component={List}
                                api={this.client}
                                user={user}
                                headerHeight={state.headerHeight}
                                timezone={Config.dateTimezone}
                                limit={Config.listLimit}
                            />
                            <Route path='/edit/'
                                component={Edit}
                                api={this.client}
                                user={user}
                                headerHeight={state.headerHeight}
                                timezone={Config.dateTimezone}
                                librEnabled={Config.librEnabled}
                                librUrl={Config.librUrl}
                                defaultHls={Config.defaultHlsUrl}
                            />
                            <Route path='/edit/:id'
                                component={Edit}
                                api={this.client}
                                user={user}
                                headerHeight={state.headerHeight}
                                timezone={Config.dateTimezone}
                                librEnabled={Config.librEnabled}
                                librUrl={Config.librUrl}
                                defaultHls={Config.defaultHlsUrl}
                            />
                            <NotFoundPage default />
                            {user.authenticated ?
                                <Redirect path='/' to='/list' /> :
                                <Redirect path='/' to='/auth' />
                            }
                        </Router>
                    </div>
                </div>
                <div class={style.alert_wrapper}>
                    {
                        !state.alertHidden &&
                        <Alert 
                            type={state.alertType}
                            title={state.alertTitle}
                            message={state.alertMessage}
                            firstButton={state.alertFirstButton}
                            secondButton={state.alertSecondButton}
                            onClose={this.closeAlert.bind(this)}
                            errorMessage={state.errorMessage}
                        />
                    }
                </div>
                <div class={style.error_wrapper}>
                    {
                        !state.errorHidden &&
                        <ErrorCatcher ref={this.errorRef}
                            timeout={Config.errorTimeout}
                        />
                    }
                </div>
            </AppContext.Provider>
        );
    }
};
