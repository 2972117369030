export interface IHash {
    [details: string]: string | number | boolean | null;
}

export default class Config {
    static readonly fallbackApiHost: string = 'https://subedit.dev4.startru.tech';
    static readonly fallbackSpellCheckerUrl: string = 'https://spellchecker.start.ru';
    static readonly fallbackLibrUrl: string = 'https://libr.start.ru';
    static readonly defaultHlsUrl: string = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
    static readonly defaultApiPath: string = '/api';
    static readonly dateTimezone: string = 'UTC +3:00';
    static readonly fallbackDefaultLang: string = 'rus';
    static readonly fallbackDateFormat: string = '%Y-%m-%d %H:%M';
    static readonly fallbackListLimit: number = 10;
    static readonly avatarSize: number = 49;
    static readonly errorTimeout: number = 5000;

    static _apiHost: null | string = null;
    static _remoteConfigFetched: boolean = false;
    static _remoteConfig: null | IHash = null;

    private static get remoteConfig(): IHash | null {
        if (!this._remoteConfigFetched) {
            try {
                this._apiHost = window.location.origin;
                this._remoteConfig = this.getRemoteConfigFromUrl(`${this._apiHost}/config`);
                this._remoteConfigFetched = true;
            }
            catch (e) {
                try {
                    this._apiHost = this.fallbackApiHost;
                    this._remoteConfig = this.getRemoteConfigFromUrl(`${this._apiHost}/config`);
                    this._remoteConfigFetched = true;
                }
                catch (e) {
                    console.warn(`Failed to get remote config, exception: ${e}`);
                    this._remoteConfig = null;
                    this._remoteConfigFetched = true;
                }
            }
        }
        return this._remoteConfig as IHash;
    }

    private static getRemoteConfigFromUrl(url: string): IHash {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, false);
        xhr.send();
        return JSON.parse(xhr.responseText);
    }

    private static getRemoteConfigValue(key: string, fallback: any = null): any {
        if (this.remoteConfig === null) {
            return fallback;
        }
        return this.remoteConfig[key]
    }

    static get apiHost(): string {
        if (this.remoteConfig === null) {
            return this.fallbackApiHost;
        }
        return this._apiHost??this.fallbackApiHost;
    }

    static get apiUrl(): string {
        return `${this.apiHost}${this.defaultApiPath}`;
    }

    static get authEnabled(): boolean {
        if (this.remoteConfig === null) {
            return false;
        }
        return this.remoteConfig['AUTH_ENABLED'] as boolean;
    }

    static get librEnabled(): boolean {
        if (this.remoteConfig === null) {
            return false;
        }
        return this.remoteConfig['LIBR_URL'] !== undefined;
    }

    static get librUrl(): string {
        return this.getRemoteConfigValue(
            'LIBR_URL',
            this.fallbackLibrUrl
        ) as string;
    }

    static get spellCheckerUrl(): string {
        return this.getRemoteConfigValue(
            'SPELLCHECK_URL',
            this.fallbackSpellCheckerUrl
        ) as string;
    }

    static get listLimit(): number {
        return this.getRemoteConfigValue(
            'DEFAULT_LIST_LIMIT',
            this.fallbackListLimit
        ) as number;
    }

    static get defaultLang(): string {
        return this.getRemoteConfigValue(
            'DEFAULT_LANG',
            this.fallbackDefaultLang
        ) as string;
    }

    static get datetimeFormat(): string {
        return this.getRemoteConfigValue(
            'DATETIME_FORMAT',
            this.fallbackDateFormat
        ) as string;
    }
}