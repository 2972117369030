import { SubtitlesListSortBy, SubtitlesListSortOrder } from './enums'

import Subtitles from './models/subtitles';
import SubtitlesList from './models/subtitles-list';
import User from './models/user';
import WaveformData from './models/waveform';
import axios from 'axios';
import { plainToInstance } from 'class-transformer';

export default class ApiClient {
    constructor(
        private readonly host: string = '',
        private readonly base_path: string = `/api`
    ) {
    }

    public get apiUrl(): string {
        return `${this.host}${this.base_path}`;
    }

    public get emptyVttUrl(): string {
        return `${this.host}/empty.vtt`;
    }

    public get emptySrtUrl(): string {
        return `${this.host}/empty.srt`;
    }

    public get loginPath(): string {
        return `${this.apiUrl}/login`;
    }

    public waveformUrl(id: string): string {
        return `${this.apiUrl}/subtitles/${id}/waveform`;
    }

    public async authCallback(session_state: string, code: string): Promise<User | null> {
        const response = await axios.get(`${this.apiUrl}/auth`, {
            params: { session_state: session_state, code: code }
        });
        return new User(response.data?.access_token, response.status === 200);
    }

    public async getSubtitles(id: string): Promise<Subtitles> {
        const response = await axios.get(`${this.apiUrl}/subtitles/${id}`);
        return plainToInstance(Subtitles, response.data as object);
    }

    public async getSubtitlesWaveform(id: string): Promise<WaveformData> {
        const response = await axios.get(this.waveformUrl(id));
        return plainToInstance(WaveformData, response.data as object);
    }

    public async listSubtitles(
        query: string = '',
        skip: number = 0,
        limit: number = 10,
        sortOrder: SubtitlesListSortOrder = 0,
        sortBy: SubtitlesListSortBy = SubtitlesListSortBy.Title
    ): Promise<SubtitlesList> {
        const parseParams = (params: any) => {
            const keys = Object.keys(params);
            let options = '';

            keys.forEach((key) => {
                const isParamTypeObject = typeof params[key] === 'object';
                const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

                if (!isParamTypeObject) {
                    options += `${key}=${params[key]}&`;
                }

                if (isParamTypeObject && isParamTypeArray) {
                    params[key].forEach((element: any) => {
                        options += `${key}=${element}&`;
                    });
                }
            });

            return options ? options.slice(0, -1) : options;
        };

        let sortParams = [];
        switch (sortBy) {
            case SubtitlesListSortBy.Title: {
                sortParams.push(`${sortOrder & SubtitlesListSortOrder.TitleDesc ? '-' : ''}title`);
                break;
            }
            case SubtitlesListSortBy.Created: {
                sortParams.push(`${sortOrder & SubtitlesListSortOrder.CreatedDesc ? '-' : ''}created`);
                break;
            }
            case SubtitlesListSortBy.Modified: {
                sortParams.push(`${sortOrder & SubtitlesListSortOrder.ModifiedDesc ? '-' : ''}modified`);
                break;
            }
            case SubtitlesListSortBy.Prepared: {
                sortParams.push(`${sortOrder & SubtitlesListSortOrder.PreparedDesc ? '-' : ''}prepared`);
                break;
            }
        }

        const response = await axios.get(`${this.apiUrl}/subtitles/list`, {
            paramsSerializer: params => parseParams(params),
            params: {
                query: query,
                skip: skip,
                limit: limit,
                sort: sortParams
            }
        });
        return plainToInstance(SubtitlesList, response.data as object);
    }

    public async createSubtitles(subtitles: Subtitles, user: User): Promise<Subtitles> {
        const json = JSON.stringify(subtitles);
        const response = await axios.post(`${this.apiUrl}/subtitles/create`, json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.authToken}`
            }
        });
        return plainToInstance(Subtitles, response.data as object);
    }

    public async updateSubtitles(subtitles: Subtitles, user: User): Promise<Subtitles> {
        const json = JSON.stringify(subtitles);
        const response = await axios.post(`${this.apiUrl}/subtitles/update`, json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.authToken}`
            }
        });
        return plainToInstance(Subtitles, response.data as object);
    }

    public async deleteSubtitles(id: string, user: User): Promise<boolean> {
        const response = await axios.delete(`${this.apiUrl}/subtitles/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.authToken}`
            }
        });
        return response.status === 200;
    }

    public async generateSrt(id: string): Promise<[File, string]> {
        const json = JSON.stringify({ id: id });
        const response = await axios.post(`${this.apiUrl}/subtitles/generate_srt`, json, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        return [response.data, `${filename}.srt`];
    }

    public async generateVtt(id: string): Promise<[File, string]> {
        const json = JSON.stringify({ id: id });
        const response = await axios.post(`${this.apiUrl}/subtitles/generate_vtt`, json, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        return [response.data, `${filename}.vtt`];
    }
}
