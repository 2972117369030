import { Component } from 'preact';
import { route } from 'preact-router';

interface Props {
    path: string;
    to: string;
}

export default class Redirect extends Component<Props> {
    componentWillMount() {
        route(this.props.to, true);
    }

    render() {
        return null;
    }
}
