import { Component, h } from 'preact';

import style from './style.css';

interface Props {
    timeout: number;
}

interface State {
    errors: Error[];
}

export default class ErrorCatcher extends Component<Props, State> {
    public pushError(error: Error) {
        let errors: Error[] = this.state.errors;
        errors.push(error);
        this.setState({ errors: errors }, () => {
            setTimeout(() => {
                let errors: Error[] = this.state.errors;
                errors.shift();
                this.setState({ errors: errors });
            }, this.props.timeout);
        });
    }

    componentWillMount() {
        this.setState({ errors: [] });
    }

    render(props: Props, state: State) {
        let container_classes: string[] = [style.errors];
        if (state.errors && state.errors.length > 0) {
            container_classes.push(style.errors_visible);
        }
        return (
            <div class={container_classes.join(' ')}>
                {state.errors && state.errors.map((error: Error, index: number) => {
                    <div class={style.error}>{error.message}</div>
                })}
            </div>
        );
    }
}
